import { Box, Menu, MenuItem, useTheme } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import { useContext, useRef, useState } from "react";
import { ThemeContext } from "../../theme-context";
import { IThemeContext, IThemeMode } from "../../theme-context/types";
import "./style.scss";

const ThemeSwitcher: React.FunctionComponent = () => {
  const theme = useTheme();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { themeMode, switchThemeMode } = useContext(
    ThemeContext,
  ) as IThemeContext;

  const handleMenuOpen = (val: boolean) => {
    setOpenMenu(val);
  };

  const handleSwitchTheme = (mode: IThemeMode) => {
    switchThemeMode(mode);
    handleMenuOpen(false);
  };

  return (
    <div className="switch-wrp">
      <Box
        alignSelf={"center"}
        m={2}
        ref={buttonRef}
        onClick={() => handleMenuOpen(!openMenu)}
        sx={{ color: theme.palette.primary.main }}
      >
        <PaletteIcon />
      </Box>

      <Menu
        open={openMenu}
        anchorEl={buttonRef.current}
        onClose={() => handleMenuOpen(false)}
      >
        <MenuItem
          onClick={() => handleSwitchTheme(IThemeMode.LIGHT)}
          selected={themeMode === IThemeMode.LIGHT}
        >
          Light
        </MenuItem>
        <MenuItem
          onClick={() => handleSwitchTheme(IThemeMode.DARK)}
          selected={themeMode === IThemeMode.DARK}
        >
          Dark
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ThemeSwitcher;
