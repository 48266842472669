import "./style.scss";

// @ts-ignore
import logo from "../../assets/hcc_@2xlogo-2.png";

import { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import CustomTextInput from "../../components/custom-text-input";
import { userLogin } from "../../api/authentication-service";
import { storeData } from "../../common/utils";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";
import Layout from "../../components/layout";

function Login(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [enableLogin, setEnableLogin] = useState(true);

  useEffect(() => {
    // clear user data
    storeData(USER_LOGIN_DATA_STORAGE_KEY, "");
  }, []);

  const login = () => {
    if (!enableLogin) return;

    setEnableLogin(false);

    userLogin(email, password).then((resp) => {
      setEnableLogin(true);

      if (resp?.data?.userData) {
        // store user data
        storeData(USER_LOGIN_DATA_STORAGE_KEY, resp.data);
        window.location.href = "/";
      } else {
        if (resp?.error?.message) setErrorMsg(resp?.error?.message);
      }
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <Layout
      pageName="login"
      dissableNavBar={true}
      dissableDrawer={true}
      showThemeSwitcher={true}
    >
      <Grid container className="login-page-container">
        <Grid item className="login-input-wrp">
          <Grid container className="logo-wrp">
            <Box
              sx={{
                flex: 1,
                display: { xs: "flex", md: "flex" },
              }}
            >
              <img className="logo" src={logo} alt="HEA" />
            </Box>
          </Grid>

          <form>
            <Grid item xs={12}>
              <Box className="input-field" sx={{ display: "flex" }}>
                <CustomTextInput
                  label="Email"
                  icon={<EmailIcon />}
                  onChange={(e) => {
                    setErrorMsg("");
                    setEmail(e.target.value);
                  }}
                  onKeyDown={handleKeyPress}
                  required={true}
                />
              </Box>
              <Box className="input-field" sx={{ display: "flex" }}>
                <CustomTextInput
                  type="password"
                  label="Password"
                  icon={<LockIcon />}
                  onChange={(e) => {
                    setErrorMsg("");
                    setPassword(e.target.value);
                  }}
                  onKeyDown={handleKeyPress}
                  required={true}
                />
              </Box>
              <Link className="forgot-password-link" href="">
                I forgot my password
              </Link>

              {errorMsg ? (
                <Typography color="error" className="error-message">
                  {errorMsg}
                </Typography>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                className="login-button"
                disabled={!enableLogin}
                variant="contained"
                onClick={() => login()}
              >
                {enableLogin ? "Login" : "..."}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Login;
