import { getData } from "./api";
import {
  programsApiResponse,
  programsResponseData,
  programsSuggestionApiResponse,
  programsSuggestionData,
} from "./models";

export const getProgramSuggestions = async (
  query: string,
): Promise<programsSuggestionData | null> => {
  try {
    let url = "/api/v1/program/suggestion";

    if (query !== "") {
      url += `?search=${query}`;
    }

    const programSuggestionApiResponse: programsSuggestionApiResponse =
      await getData<programsSuggestionApiResponse>(url);

    if (!programSuggestionApiResponse || !programSuggestionApiResponse.data) {
      console.log("Invalid response from server");
      return null;
    }

    return programSuggestionApiResponse.data; // return programSuggestionApiResponse.data.slice(0, 5);
  } catch (error) {
    console.error("Error fetching programs data:", error);
    return null;
  }
};

export const getProgramsData = async (
  query: string,
): Promise<programsResponseData | null> => {
  try {
    let url = "/api/v1/program/list";

    if (query !== "") {
      url += `?search=${query}`;
    }

    const programsApiResponse: programsApiResponse =
      await getData<programsApiResponse>(url);

    if (!programsApiResponse || !programsApiResponse.data) {
      console.log("Invalid response from server");
      return null;
    }

    return programsApiResponse.data;
  } catch (error) {
    console.error("Error fetching programs data:", error);
    return null;
  }
};
