import { Theme, createTheme } from "@mui/material";

export const AppLightTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#001e60",
    },
    secondary: {
      main: "#94edff",
    },
    background: {
      default: "rgb(243,252,255)",
      paper: "rgb(255,252,255)",
    },
    text: {
      primary: "#001e60",
    },
  },
  typography: {
    fontWeightBold: 10,
    body1: {
      fontSize: 15,
      fontWeight: "lighter",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bolder",
    },
    h4: {
      fontSize: 13,
      fontWeight: "bolder",
    },
    h5: {
      fontSize: 11,
      fontWeight: "bolder",
    },
  },
});

export const AppDarkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#cbcfd7",
    },
    secondary: {
      main: "#122136",
    },
    background: {
      default: "rgb(33,37,39)",
      paper: "rgb(41,44,49)",
    },
  },
  typography: {
    fontWeightBold: 10,
    body1: {
      fontSize: 15,
      fontWeight: "lighter",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bolder",
    },
    h4: {
      fontSize: 13,
      fontWeight: "bolder",
    },
    h5: {
      fontSize: 11,
      fontWeight: "bolder",
    },
  },
});
