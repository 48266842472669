import { useEffect, useState } from "react";
import { programSuggestionSearchResult, programsData } from "../../api/models";
import {
  getProgramSuggestions,
  getProgramsData,
} from "../../api/program-services";
import DataTable from "../../components/data-table";
import Layout from "../../components/layout";
import SearchBar from "../../components/search-bar";
import { ProgramDataColumns } from "./program-table-utils";
import FormattedText from "../../components/formatted-text";
import "./style.scss";
import { Grid, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { retrieveUserData } from "../../common/utils";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";

function Programs(props: any) {
  useEffect(() => {
    const user = retrieveUserData(USER_LOGIN_DATA_STORAGE_KEY);
    if (!user?.userData) {
      window.location.href = "/login";
    }
    console.log("USER LOGGED IN :: ", user?.userData?.email);
  }, []);

  const [suggestions, setSuggestions] = useState<
    programSuggestionSearchResult[]
  >([]);
  const [programs, setPrograms] = useState<programsData[]>([]);
  const [programsTableColumns, setProgramsTableColumns] =
    useState<GridColDef[]>(ProgramDataColumns);

  const getSuggestion = async (
    query: string,
  ): Promise<programSuggestionSearchResult[]> => {
    const result: programSuggestionSearchResult[] = [];

    try {
      getProgramSuggestions(query)
        .then((suggestions) => {
          suggestions?.programSuggestions?.forEach((suggestion) =>
            result.push({
              id: suggestion.programCode,
              value: suggestion.programName,
            }),
          );

          return result;
        })
        .then((data) => {
          setSuggestions(data);
        });

      return result;
    } catch (error) {
      console.error("Error fetching program suggestions:", error);
      throw error;
    }
  };

  const getPrograms = async (query: string) => {
    const proramsRespData = await getProgramsData(query);

    // update programsDataColums

    proramsRespData?.stateList?.forEach((state) => {
      programsTableColumns.push({
        field: state.stateCode,
        minWidth: 10,
        maxWidth: 70,
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <div
              style={{
                lineHeight: "normal",
                // fontWeight: "bold",
                width: "100%",
                justifyItems: "center",
                display: "flex",
              }}
            >
              <Typography variant="h4">{state.stateCode}</Typography>
            </div>
          );
        },
        renderCell: (params: GridRenderCellParams<programsData>) => {
          return (
            <Eligibility
              eligibility={
                params.row.programStateData[state.stateCode]?.eligibility
              }
            ></Eligibility>
          );
        },
      });
    });

    setProgramsTableColumns(programsTableColumns);

    if (proramsRespData?.programs) {
      setPrograms(proramsRespData.programs);
    } else {
      setPrograms([]);
    }

    return;
  };

  const licensureDisclosureHtmlText = `<p>HCC offers degree and certificate programs that prepare students to be eligible for professional licensure or certification that qualify them for their chosen career path. The chart above provides information about how the degree/certificate programs offered at HCC meet state requirements across the country.</br></br> Even when graduates are eligible for a national certification exam, there are instances when individual states have requirements beyond those that Florida has mandated.</br></br>  Research is typically updated every 6 months. Please reach out to the program direct to confirm.</p>`;

  return (
    <Layout pageName="programs">
      <Grid container className="programs-page-container">
        <Grid item lg={6} md={6} xs={10}>
          <SearchBar
            suggestions={suggestions}
            suggestionsApi={getSuggestion}
            searchData={getPrograms}
            placeHolder="search programs..."
          ></SearchBar>
        </Grid>

        {programs?.length ? (
          <Grid item className="table-container">
            <DataTable
              getRowId={(row) => row.programCode}
              rows={programs}
              columns={programsTableColumns}
              hideFooterPagination={true}
              disablePagination={true}
              hideFooter={true}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item lg={7} md={7} xs={10} className="licensure-disclosure-wrp">
          <FormattedText
            heading="Licensure Disclosure"
            contentHtmlText={licensureDisclosureHtmlText}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

function Eligibility(props: any) {
  const [eligibilityCode, className] = encodeEligibility(props.eligibility);
  return <div className={className}>{eligibilityCode}</div>;
}

const CSS_CLASS_ELIBIBILITY_YES: string = "eligibility-yes";
const CSS_CLASS_ELIBIBILITY_NO: string = "eligibility-no";
const CSS_CLASS_ELIBIBILITY_UNCERTAIN: string = "eligibility-uncertain";

function encodeEligibility(value: string) {
  if (!value || value.toLowerCase() === "no") {
    return ["\u2717", CSS_CLASS_ELIBIBILITY_NO];
  }
  if (value.toLowerCase() === "yes") {
    return ["\u2714", CSS_CLASS_ELIBIBILITY_YES];
  }
  return ["\u2013", CSS_CLASS_ELIBIBILITY_UNCERTAIN];
}

export default Programs;
