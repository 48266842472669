import { Typography } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";

export const ProgramDataColumns: GridColDef[] = [
  {
    field: "programName",
    minWidth: 250,
    maxWidth: 300,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <div
          style={{
            lineHeight: "normal",
            fontWeight: "bold",
            width: "100%",
            justifyItems: "center",
            display: "flex",
          }}
        >
          <Typography variant="h4">Program</Typography>
        </div>
      );
    },
  },
  {
    field: "programCode",
    minWidth: 5,
    maxWidth: 70,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <div
          style={{
            lineHeight: "normal",
            fontWeight: "bold",
            width: "100%",
            justifyItems: "center",
            display: "flex",
          }}
        >
          <Typography variant="h4">Code</Typography>
        </div>
      );
    },
  },
];
