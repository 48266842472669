import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import React from "react";

type StudentSettingsProps = {
  id: number;
  clickedRow: number;
  setClickedRow: React.Dispatch<React.SetStateAction<number>>;
};

export const StudentSettings = (props: StudentSettingsProps) => {
  const handleRowClick = (value: number) => {
    if (props.clickedRow === value) {
      value = 0;
    }
    props.setClickedRow(value);
  };

  return (
    <div className="settings-wrp">
      <ViewCompactIcon onClick={() => handleRowClick(props.id)} />

      {props.clickedRow === props.id ? (
        <div className="settings-container">
          <div className="settings-option">
            <AddBoxIcon />
            <span>Add Student</span>
          </div>

          <div className="settings-option">
            <ContentPasteGoIcon />
            <span>Export</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
