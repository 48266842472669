import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/login/index";
import Programs from "./pages/programs/index";
import Students from "./pages/students";
import { ThemeContextProvider } from "./theme-context";

function App() {
  return (
    <ThemeContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Programs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/students" element={<Students />} />
        </Routes>
      </BrowserRouter>
    </ThemeContextProvider>
  );
}
export default App;
