import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import "./style.scss";

// @ts-ignore
import logo from "../../assets/hcc_@2xlogo-2.png";
import ThemeSwitcher from "../theme-switcher";
import { useTheme } from "@mui/material";

type NavBarContents = {
  name: string;
  pageName: string;
};

// const pages: NavBarContents[] = ['Programs', 'My Students', 'Disclosures', 'Reports'];
const pages: NavBarContents[] = [
  {
    name: "Programs",
    pageName: "programs",
  },
  {
    name: "My Students",
    pageName: "students",
  },
  {
    name: "Disclosures",
    pageName: "disclosures",
  },
  {
    name: "Reports",
    pageName: "reports",
  },
];

type NavbarProps = {
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  pageName: string;
};

function NavBar(props: NavbarProps) {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <AppBar
      className="navbar-container"
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flex: 1,
              display: { xs: "flex", md: "flex" },
            }}
          >
            <img className="logo" src={logo} alt="HEA" />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => navigate(`/${page.pageName}`)}
                sx={{ my: 2, display: "block" }}
                className={
                  props.pageName.toLocaleLowerCase() ===
                  page.pageName.toLocaleLowerCase()
                    ? "current-page"
                    : ""
                }
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <ThemeSwitcher />

            <IconButton
              size="large"
              onClick={() => {
                if (props.toggleDrawer) props.toggleDrawer(true);
              }}
              // color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
