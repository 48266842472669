import { postData } from "./api";
import { loginApiRequest, loginApiResponse } from "./models";

export const userLogin = async (
  email: string,
  password: string,
): Promise<loginApiResponse | null> => {
  try {
    let url = "/api/v1/user/login";

    const loginApiReq: loginApiRequest = {
      data: {
        email: email,
        password: password,
      },
    };

    const loginApiResponse: loginApiResponse = await postData<loginApiResponse>(
      url,
      loginApiReq,
    );

    return loginApiResponse;
  } catch (error) {
    console.error("Error fetching login response data:", error);
    return null;
  }
};

export const userLogOut = async (): Promise<any> => {
  try {
    let url = "/api/v1/user/logout";

    const loginOutApiResponse: any = await postData<any>(url);

    return loginOutApiResponse;
  } catch (error) {
    console.error("Error fetching logout response data:", error);
    return null;
  }
};
