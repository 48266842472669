import { userLogOut } from "../../api/authentication-service";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";
import { storeData } from "../../common/utils";

export const DrawerClickAction = (key: string) => {
  switch (key) {
    case "logout": {
      // clear user data
      storeData(USER_LOGIN_DATA_STORAGE_KEY, "");
      userLogOut().then((data) => {
        console.log(data);
        window.location.href = "/login";
      });
      break;
    }

    default: {
      break;
    }
  }
};
