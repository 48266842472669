import axios, { AxiosError, AxiosResponse } from "axios";

const baseURL = "https://portal.eduiq.io";

interface ErrorResponse {
  status: string;
  error: {
    message: string;
  };
}

// Example usage:
const errorResponse: ErrorResponse = {
  status: "error",
  error: {
    message: "Something went wrong",
  },
};

// Function to handle API errors
const handleApiError = (error: AxiosError | any): any => {
  if (axios.isAxiosError(error) && error?.response) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }

    if (error.response?.data) return error.response.data;
  }

  console.error("Unexpected Error:", error);
  return errorResponse;
};

// Generic function for making GET requests
const getData = async <T>(url: string): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await axios.get<T>(`${baseURL}${url}`, {
      withCredentials: true, // Allow cookies to be sent and stored
    });

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making POST requests
const postData = async <T>(url: string, data?: any): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await axios.post<T>(
      `${baseURL}${url}`,
      data,
      {
        withCredentials: true, // Allow cookies to be sent and stored
      },
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making PUT requests
const putData = async <T>(url: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await axios.put<T>(
      `${baseURL}${url}`,
      data,
      {
        withCredentials: true, // Allow cookies to be sent and stored
      },
    );
    return response;
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making DELETE requests
const deleteData = async (url: string): Promise<void> => {
  try {
    await axios.delete(`${baseURL}${url}`, {
      withCredentials: true, // Allow cookies to be sent and stored
    });
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making PATCH requests
const patchData = async <T>(url: string, data: T): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await axios.patch<T>(
      `${baseURL}${url}`,
      data,
      {
        withCredentials: true, // Allow cookies to be sent and stored
      },
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export { getData, postData, putData, deleteData, patchData };
