import { Typography } from "@mui/material";

type FormattedTextProps = {
  heading?: string;
  contentHtmlText: string;
};

// FormattedText is used to conditionally renders an <h3> element with the provided heading and displays HTML-formatted content using dangerouslySetInnerHTML.
const FormattedText = ({ heading, contentHtmlText }: FormattedTextProps) => {
  return (
    <div>
      {heading?.length ? (
        <div>
          <Typography variant="h3">{heading}</Typography>
        </div>
      ) : (
        <></>
      )}

      <div>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: contentHtmlText }}
        />
      </div>
    </div>
  );
};

export default FormattedText;
