import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./style.scss";
import { DrawerClickAction } from "./action";

type DrawerProps = {
  showDrawer: boolean;
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function SwipeableTemporaryDrawer(props: DrawerProps) {
  const toggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
    };

  const list = (anchor: string) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box className="drawer-top-icons">
        <IconButton
          size="large"
          onClick={() => props.toggleDrawer && props.toggleDrawer(false)}
        >
          <MenuIcon />
        </IconButton>
        <div className="drawer-extra-icons">
          <span>
            <NotificationsIcon />
          </span>
          <span>
            <AccountCircleIcon />
          </span>
        </div>
      </Box>
      <List>
        {[
          { text: "Profile", key: "profile" },
          { text: "Settings", key: "settings" },
          { text: "Manage Users", key: "manageUsers" },
          { text: "My Program", key: "myProgram" },
          { text: "Programs", key: "programs" },
          { text: "Students", key: "students" },
          { text: "Logout", key: "logout" },
        ].map((data, index) => (
          <ListItem key={data.text} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={data.text}
                className="drawer-list-text"
                onClick={() => DrawerClickAction(data.key)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <div className="drawer-list-bottom">
        <ListItemButton className="drawer-list-bottom-button">
          <ListItemText
            primary={"Public Disclosure"}
            className="drawer-list-text"
          />
        </ListItemButton>
        <ListItemButton className="drawer-list-bottom-button">
          <ListItemText
            primary={"Location Change Form"}
            className="drawer-list-text"
          />
        </ListItemButton>
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={props.showDrawer}
          onClose={() => props.toggleDrawer(false)}
          onOpen={toggleDrawer("right", true)}
          className="swipeable-drawer"
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
