import { useState } from "react";
import { StudentsData } from "../../api/models";
import DataTable from "../../components/data-table";
import Layout from "../../components/layout";
import { StudentDataColumns } from "./student-table-utils";
import "./style.scss";
import { Grid } from "@mui/material";

function Students(props: any) {
  const students = useState<StudentsData[]>([]);
  const [clickedRow, setClickedRow] = useState(0);

  return (
    <Layout pageName="students">
      <Grid container lg={5} md={6} xs={10} className="students-page-container">
        <Grid item xs={12}>
          {/* <SearchBar
            suggestionsApi={getSuggestion}
            searchData={getStudents}
            placeHolder="search students..."
          ></SearchBar> */}
        </Grid>

        {students?.length ? (
          <Grid item className="table-container">
            <DataTable
              getRowId={(row) => row.email}
              rows={students}
              columns={StudentDataColumns(clickedRow, setClickedRow)}
              hideFooterPagination={true}
              disablePagination={true}
              hideFooter={true}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Layout>
  );
}

export default Students;
