import "./style.scss";
import React, { useState } from "react";
import { TextField, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface CustomTextInputProps {
  type?: string;
  label: string;
  required?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  icon?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
  type,
  label,
  required,
  fullWidth,
  multiline,
  rows,
  icon,
  onChange,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
      }}
    >
      <div className="input-icon">{icon}</div>
      <TextField
        label={label}
        type={
          type && (!type || (showPassword && type === "password"))
            ? "text"
            : type
        }
        variant="standard"
        sx={{ width: "100%" }}
        onChange={onChange}
        onKeyDown={() => (props?.onKeyDown ? props.onKeyDown : "")}
        required={required}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        {...props}
      />
      {type && type === "password" ? (
        <div
          className="show-password-icon"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CustomTextInput;
