export function storeData(key: string, data: any) {
  const dataString = JSON.stringify(data);
  localStorage.setItem(key, dataString);
}

interface UserData {
  accessToken: string;
  userData: {
    id: string;
    name: string;
    email: string;
  };
}

export function retrieveUserData(key: string): UserData | null {
  const dataString = localStorage.getItem(key);
  if (dataString) {
    const data = JSON.parse(dataString) as UserData;
    return data;
  }
  return null;
}
