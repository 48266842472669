import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { Grid, TextField, InputAdornment } from "@mui/material";
import "./style.scss";
import { useTheme } from "@mui/material/styles";

type SearchResult = {
  id: string;
  value: string;
};

type SearchProps = {
  placeHolder: string;
  suggestions: SearchResult[];
  suggestionsApi: (input: string) => Promise<SearchResult[]>;
  searchData: (input: string) => void;
};

function SearchBar(props: SearchProps) {
  const theme = useTheme();

  const [query, setQuery] = useState("");
  const [recentSearch, setRecentSearch] = useState<string[]>([]);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isInputSelected, setIsInputSelected] = useState(false);

  const updateQuerySuggestion = (value: string) => {
    setQuery(value);

    // clear timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // timeout for 3 seconds
    const newTimeoutId = setTimeout(() => {
      handleSearch(value);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateQuerySuggestion(value);
  };

  const handleInputFocus = (value: boolean) => {
    setIsInputSelected(value);
  };

  const handleSearch = (query: string) => {
    // call get suggestion api
    props.suggestionsApi(query);

    if (recentSearch.includes(query)) {
      return;
    }

    setRecentSearch((prevQueries) => [query, ...prevQueries.slice(0, 4)]);
  };

  const handleSelectSuggestionItem = (item: SearchResult) => {
    setQuery(item.value);

    setIsInputSelected(false);

    props.searchData(item.value);
  };

  const handleSelectRecentItem = (value: string) => {
    updateQuerySuggestion(value);

    props.searchData(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsInputSelected(false);
      props.searchData(query);
    } else {
      setIsInputSelected(true);
    }
  };

  // unmount timeout
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Grid className="search-wrp" xs={12}>
      <TextField
        className="search-input"
        fullWidth
        inputProps={{
          autoComplete: "off",
        }}
        // label={props.placeHolder}
        placeholder={props.placeHolder}
        value={query}
        onChange={handleInputChange}
        onFocus={() => handleInputFocus(true)}
        onBlur={() => handleInputFocus(false)}
        onKeyDown={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className="search-icon"></SearchIcon>
            </InputAdornment>
          ),
        }}
        sx={{ backgroundColor: theme.palette.background.paper }}
      />

      {/* {isInputSelected && (suggestions?.length || recentSearch?.length) ?  */}
      <Grid
        container
        className={
          "search-drop-down" +
          (isInputSelected &&
          (props.suggestions?.length || recentSearch?.length)
            ? " show"
            : " hide")
        }
        sx={{
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.primary.main,
        }}
      >
        <Grid item xs={12}>
          <ul
            className={
              recentSearch?.length && props.suggestions?.length
                ? "suggestions-list"
                : ""
            }
          >
            {props.suggestions?.map((item) => (
              <li
                key={item.id}
                onMouseDown={() => handleSelectSuggestionItem(item)}
              >
                {item.value}
              </li>
            ))}
          </ul>
        </Grid>
        {recentSearch?.length ? <span> Recent searches</span> : <></>}

        <Grid item xs={12}>
          <ul>
            {recentSearch.map((item) => (
              <li
                key={item}
                className="recent-items"
                onMouseDown={() => handleSelectRecentItem(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      {/* } */}
    </Grid>
  );
}

export default SearchBar;
