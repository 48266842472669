import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import { StudentsData } from "../../api/models";
import React from "react";
import { StudentSettings } from "./student-settings";

export const StudentDataColumns = (
  clickedRow: number,
  setClickedRow: React.Dispatch<React.SetStateAction<number>>,
): GridColDef[] => {
  return [
    {
      field: "name",
      minWidth: 150,
      maxWidth: 200,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <div>Name</div>
          </div>
        );
      },
    },
    {
      field: "email",
      minWidth: 150,
      maxWidth: 200,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <div>Email</div>
          </div>
        );
      },
    },
    {
      field: "state",
      minWidth: 5,
      maxWidth: 70,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <div>State</div>
          </div>
        );
      },
    },
    {
      field: "program",
      minWidth: 200,
      maxWidth: 300,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <div>Program</div>
          </div>
        );
      },
    },
    {
      field: "disclosure",
      minWidth: 50,
      maxWidth: 100,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <div>Disclosure</div>
          </div>
        );
      },
    },
    {
      field: "date",
      minWidth: 50,
      maxWidth: 100,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <div>Date</div>
          </div>
        );
      },
    },

    {
      field: "settings",
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <SettingsIcon />
          </div>
        );
      },
      renderCell: (params: GridRenderCellParams<StudentsData>) => {
        return (
          <StudentSettings
            id={params.row.id + 1}
            clickedRow={clickedRow}
            setClickedRow={setClickedRow}
          />
        );
      },
    },
  ];
};
