import React, { useState } from "react";
import "./style.scss";
import ResponsiveAppBar from "../nav-bar";
import SwipeableTemporaryDrawer from "../drawer-menu";
import { Paper } from "@mui/material";
import ThemeSwitcher from "../theme-switcher";

type LayoutProps = {
  children: React.ReactNode;
  pageName: string;
  dissableNavBar?: boolean;
  dissableDrawer?: boolean;
  showThemeSwitcher?: boolean;
};
function Layout(props: LayoutProps) {
  const [showDrawer, toggleDrawer] = useState(false);

  return (
    <Paper className={"layout-container"}>
      {props.showThemeSwitcher ? (
        <div className="theme-switcher-wrp">
          <ThemeSwitcher />
        </div>
      ) : (
        <></>
      )}

      <div className="layout-wrp">
        {!props.dissableNavBar ? (
          <ResponsiveAppBar
            pageName={props.pageName}
            toggleDrawer={toggleDrawer}
          ></ResponsiveAppBar>
        ) : (
          <></>
        )}

        {!props.dissableDrawer ? (
          <SwipeableTemporaryDrawer
            showDrawer={showDrawer}
            toggleDrawer={toggleDrawer}
          ></SwipeableTemporaryDrawer>
        ) : (
          <></>
        )}
        <div className={"body-container " + props.pageName}>
          {props.children}
        </div>
      </div>
    </Paper>
  );
}

export default Layout;
